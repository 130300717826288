import {
  DateField,
  FunctionField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from "react-admin";
import { ColorField } from "react-admin-color-picker";
import { JsonField } from "react-admin-json-view";
import { ClipsList } from "../clips";
import { ProfileActivityList } from "../profileActivity";
import { WalletLists } from "../wallets";

export default () => {
  const { record } = useShowController();

  return (
    <>
      <Show
        title={
          record?.username
            ? `Profile ${record.username} details`
            : "Profile details"
        }
      >
        <SimpleShowLayout>
          <h3>User details</h3>
          <FunctionField
            label="Username"
            render={(record) =>
              record?.username ? <TextField source="username" /> : "-"
            }
          />
          <FunctionField
            label="Email"
            render={(record) => record?.email ?? "-"}
          />
          <DateField source="createdAt" label="Date joined" />
          <TextField source="id" />
          <TextField source="userId" label="Privy user id" />
          <FunctionField
            label="Avatar Color"
            render={(record) =>
              record?.avatarColor ? <ColorField source="avatarColor" /> : "-"
            }
          />
          <FunctionField
            label="Avatar Image"
            render={(record) =>
              record?.avatarUrl ? (
                <ImageField source="avatarUrl" label="Avatar Image" />
              ) : (
                "-"
              )
            }
          />
          <TextField source="status" />
          <FunctionField
            label="Privy Wallet Address"
            render={(record) => record?.privyWalletAddress ?? "-"}
          />
          <FunctionField
            label="Golden Ticket"
            render={(record) =>
              record?.goldenTicketQty > 0
                ? `Yes (${record?.goldenTicketQty})`
                : "-"
            }
          />
          <FunctionField
            label="Banned"
            render={(record) => (record?.isBanned ? "Yes" : "-")}
          />
          <FunctionField
            label="Internal Note"
            render={(record) =>
              record?.internalNote !== "" ? record.internalNote : "-"
            }
          />
          <TextField source="userLevel" />
          <TextField source="ipRisk" />
          <TextField source="countryRisk" />
          <TextField source="inviteCode" />
          <JsonField
            source="oauthProviderMetadata"
            jsonString={false}
            label="Auth Provider Metadata"
            reactJsonOptions={{
              name: null,
            }}
          />
          <h3>User clips</h3>
          <ClipsList
            resource="clips"
            title={<></>}
            filter={{ ownerId: record?.id }}
          />
          <h3>Profile activity</h3>
          <ProfileActivityList
            resource="profile-activity"
            title={<></>}
            filter={{ profileId: record?.id }}
          />
          <h3>Wallets</h3>
          <WalletLists
            resource="wallets"
            title={<></>}
            filter={{ profileId: record?.id }}
          />
        </SimpleShowLayout>
      </Show>
    </>
  );
};
