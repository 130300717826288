import {
  Create,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export default () => (
  <Create redirect="/invite-codes">
    <SimpleForm>
      <ReferenceInput
        isRequired
        source="ownerId"
        reference="profiles"
        label="Owner"
        perPage={1000}
        sort={{ field: 'username', order: 'ASC' }}
      >
        <AutocompleteInput 
          optionText="username"
          filterToQuery={searchText => ({ username: searchText })}
          fullWidth
        />
      </ReferenceInput>
      <TextInput source="code" />
      <NumberInput source="uses" />
    </SimpleForm>
  </Create>
);
